import { FontSize, FontVariant } from "@/types/enums";
import { type ReactNode } from "react";
import ConvertedHTML from "@bespeak/ui/src/ConvertedHTML";

export type TypographyProps = {
    truncate?: boolean;
    size?: FontSize;
    variant?: FontVariant;
    children?: ReactNode;
    content?: string | null;
    bold?: boolean;
    italic?: boolean;
    block?: boolean;
    forElement?: string;
    className?: string;
    color?: "dark" | "light" | "brand" | "inherit" | "neutral-700";
    fontType?: "title" | "body";
};

export const Typography = Object.assign(
    function Typography({
        truncate,
        size,
        variant,
        children,
        fontType,
        forElement,
        className,
        content,
        color = "dark",
        bold = false,
        italic = false,
        block = false,
    }: TypographyProps) {
        const Element = variant ?? FontVariant.Div;
        const boldClass = bold ? "font-bold" : "font-normal";
        const italicClass = italic ? "italic" : "not-italic";
        const blockClass = block ? "block" : "inline";

        const labelFor =
            variant === FontVariant.Label
                ? {
                      htmlFor: forElement,
                  }
                : {}; //Maybe move this to a Label component, but typography will also suffice just fine

        const classNameString = `${size} ${blockClass} ${boldClass} ${italicClass} font-${fontType} ${variant} text-${color} ${className}`;

        return (
            <Element {...labelFor} className={`${classNameString}`}>
                {children ? (
                    children
                ) : (
                    <ConvertedHTML truncate={truncate} value={content} />
                )}
            </Element>
        );
    },
    {
        Size: FontSize,
        Variant: FontVariant,
    },
);

export default Typography;
